import './Tos.css';
import TosLogo from './media/Tos.gif';

const Tos = () => {
  return (
    <>
      <h1 id="Tos-h1">Terms of Service</h1>
      <img id="Tos-img"alt="Terms of Service" src={TosLogo} />
    </>
  );
}

export default Tos;
