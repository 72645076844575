import './Privacy.css';
import PrivacyLogo from './media/privacy.gif';

const Privacy = () => {
  return (
    <>
      <h1 id="Privacy-h1">Privacy Policy</h1>
      <img id="Privacy-img"alt="Privacy Policy" src={PrivacyLogo} />
    </>
  );
}

export default Privacy;
