import './ItemInterface.css';

const ItemInterface = (props) => {
	return (
		<div id="ItemInterface-container">
			<span>{props.header}</span>
			<img alt={props.name} src={props.imgSrc} />
			<span>{props.name}</span>
			<span>{props.msg}</span>
		</div>
	);
}

export default ItemInterface;