import Trade from '../components/Trade/Trade';

const TradeView = () => {
  return (
    <>
      <Trade />
    </>
  );
}

export default TradeView;
