import './Trade.css';
import TradeLogo from './media/trade.gif';

const Trade = () => {
  return (
    <>
      <h1 id="Trade-h1">Trade With Us</h1>
      <img id="Trade-img"alt="Trade With Us" src={TradeLogo} />
    </>
  );
}

export default Trade;
