import Privacy from '../components/Privacy/Privacy';

const PrivacyView = () => {
  return (
    <>
      <Privacy />
    </>
  );
}

export default PrivacyView;
