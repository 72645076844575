import './Signup.css';
import SignupLogo from './media/signup.gif';

const Signup = () => {
  return (
    <>
      <h1 id="Signup-h1">Signup</h1>
      <img id="Signup-img"alt="Signup" src={SignupLogo} />
    </>
  );
}

export default Signup;
