import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, DotGroup, ImageWithZoom } from 'pure-react-carousel';
import css from 'pure-react-carousel/dist/react-carousel.es.css';
import './SlickSlide.css';
import image1 from './media/1.jpg';
import image2 from './media/2.jpg';
import image3 from './media/3.jpg';
import image4 from './media/4.jpg';
import image5 from './media/5.jpg';
import image6 from './media/6.jpg';
import image7 from './media/7.jpg';
import image8 from './media/8.jpg';
import image9 from './media/9.jpg';

const SlickSlide = () => {
  return (
	<>
      <CarouselProvider
        naturalSlideWidth={3}
        naturalSlideHeight={2}
				totalSlides={9}
				isPlaying={true}
      >
				<div className="Slide-box">
					<Slider className={css.slider}>
						<Slide index={0}><ImageWithZoom src={image1} /></Slide>
						<Slide index={1}><ImageWithZoom src={image2} /></Slide>
						<Slide index={2}><ImageWithZoom src={image3} /></Slide>
						<Slide index={3}><ImageWithZoom src={image4} /></Slide>
						<Slide index={4}><ImageWithZoom src={image5} /></Slide>
						<Slide index={5}><ImageWithZoom src={image6} /></Slide>
						<Slide index={6}><ImageWithZoom src={image7} /></Slide>
						<Slide index={7}><ImageWithZoom src={image8} /></Slide>
						<Slide index={8}><ImageWithZoom src={image9} /></Slide>
					</Slider>
					<div className="Back">
						<ButtonBack className="Dir-button">&#8249;</ButtonBack>
					</div>
					<div className="Next">
						<ButtonNext className="Dir-button">&#8250;</ButtonNext>
					</div>
					<DotGroup />
				</div>
			</CarouselProvider>

    </>
  );
}

export default SlickSlide;
