import './FooterContent.css'
import logo from './media/crypt-extra-logo.png';
import { Link } from 'react-router-dom'

const FooterContent = () => {

	return (
		<>
			<div id="FooterContent-container">
				<div>
        <Link to='/'><img id="Footer-logo" alt="crypt-extra" src={logo} /></Link>
				</div>
				<div>
					<p className="Footer-headers">Company</p>
					<p className="Footer-links"><Link to='/trade'>About us</Link></p>
				</div>
				<div>
					<p className="Footer-headers">Legal</p>
					<p className="Footer-links"><Link to='/privacy'>Privacy Policy</Link></p>
					<p className="Footer-links"><Link to='/tos'>Terms of Use</Link></p>
					<p className="Footer-links"><Link to='/trade'>Security</Link></p>
					<p className="Footer-links"><Link to='/trade'>Risk Warning</Link></p>
				</div>
				<div>
					<p className="Footer-headers">Service</p>
					<p className="Footer-links"><Link to='/trade'>Fees</Link></p>
					<p className="Footer-links"><Link to='/trade'>Referrals</Link></p>
				</div>
				<div>
					<p className="Footer-headers">Help</p>
					<p className="Footer-links"><Link to='/trade'>FAQ</Link></p>
				</div>
			</div>
			<p id="Copyright">Copyright © 2023 Crypt-Extra. All rights reserved</p>
		</>
	);
}

export default FooterContent;
