import './ExplanationHowTo.css';
import ItemInterface from './children/ItemInterface/ItemInterface';
import Register from './media/Register.webp';
import Trading from './media/Trading.webp';
import Promo from './media/Promo.webp';
import Money from './media/Money.webp';

const ExplanationHowTo = () => {

	return (
		<div id="ExplanationHowTo-container">
			<h1>How it Works</h1>
			<div id="Flex-box">
				<ItemInterface header="Step 1" name="Register" imgSrc={Register} 
						msg="Registration is a very easy process. Follow step by step and sign in." />
				<ItemInterface header="Step 2" name="Start trading" imgSrc={Trading} 
						msg="We support all types of cryptocurrency." />
				<ItemInterface header="Step 3" name="Type promo code" imgSrc={Promo} 
						msg="Use promo code to increase your income." />
				<ItemInterface header="Step 4" name="Earn money" imgSrc={Money} 
						msg="Earn money on our platform safely and legally." />
			</div>
		
		</div>
	);
}

export default ExplanationHowTo;
