import Tos from '../components/Tos/Tos';

const TosView = () => {
  return (
    <>
      <Tos />
    </>
  );
}

export default TosView;
