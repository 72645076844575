import './Navbar.css';
import logo from './media/crypt-extra-logo.png';
import hamburgerIcon from './media/hamburger-icon.gif';
import { Link } from 'react-router-dom'

const Navbar = () => {
  // For toggling nav menu on hamburger menu icon click
  const hamburgerSwitch = () => {	
	const nav_ul = document.getElementById("Nav-ul");

	const get_css_prop = getComputedStyle(nav_ul);
	const display = get_css_prop.getPropertyValue("display");
  
	nav_ul.style["display"] = ( display === "none" ) ? "contents" : "none";
  }

  const hideNav = () => {
    const nav_ul = document.getElementById("Nav-ul");

    nav_ul.style["display"] = (window.innerWidth >= 700) ? "contents" : "none";
  }

  return (
	<>
	  <div id="Nav-container">
      <div id="Logo-box">
        <Link to='/'><img alt="crypt-extra" src={logo} /></Link>
      </div>
      <div id="Nav-box">
        <ul id="Nav-ul">
          <li className="Li-items" onClick={hideNav}><Link to='/trade'>Trade</Link></li>
          <li className="Li-items" onClick={hideNav}><Link to='/tos'>Terms of Use</Link></li>
          <li className="Li-items" onClick={hideNav}><Link to='/privacy'>Privacy Policy</Link></li>
          <li className="Li-items" onClick={hideNav}><Link to='/login'>Login</Link></li>
          <li className="Li-items" onClick={hideNav}><Link to='/signup'>Signup</Link></li>
        </ul>
      </div>
	  </div>
    <div id="Hamburger-container" onClick={hamburgerSwitch}>
      <img alt="hamburger-icon" src={hamburgerIcon} />
    </div>
	</>
  );
}

export default Navbar;
