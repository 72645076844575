import React from 'react'
import './App.css';
import Navbar from './components/NavBar/Navbar';
import FooterContent from './components/FooterContent/FooterContent';
import HomeView from './views/HomeView';
import TradeView from './views/TradeView';
import TosView from './views/TosView';
import PrivacyView from './views/PrivacyView';
import LoginView from './views/LoginView';
import SignupView from './views/SignupView';
import { BrowserRouter as Router, Routes, Route, Outlet} from 'react-router-dom';

const Dashboard = () => {
  return (
    <>
      <header>
        <Navbar />
      </header>
      <section>
        <Outlet />
      </section>
      <footer>
        <FooterContent />
      </footer>
    </>
  );
}

function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<Dashboard />} >
          <Route index element={<HomeView />} />
          <Route path='/trade' element={<TradeView />} />
          <Route path='/tos' element={<TosView />} />
          <Route path='/privacy' element={<PrivacyView />} />
          <Route path='/login' element={<LoginView />} />
          <Route path='/signup' element={<SignupView />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
