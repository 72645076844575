import './Login.css';
import LoginLogo from './media/login.gif';

const Login = () => {
  return (
    <>
      <h1 id="Login-h1">Login</h1>
      <img id="Login-img"alt="Login" src={LoginLogo} />
    </>
  );
}

export default Login;
