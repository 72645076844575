import Signup from '../components/Signup/Signup';

const SignupView = () => {
  return (
    <>
      <Signup />
    </>
  );
}

export default SignupView;
