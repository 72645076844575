import SlickSlide from '../components/SlickSlide/SlickSlide';
import ExplanationHowTo from '../components/ExplanationHowTo/ExplanationHowTo';

const HomeView = () => {
  return (
    <>
      <SlickSlide />
		  <ExplanationHowTo />
    </>
  );
}

export default HomeView;
