import Login from '../components/Login/Login';

const LoginView = () => {
  return (
    <>
      <Login />
    </>
  );
}

export default LoginView;
